/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

import './styles/app.css';

// start the Stimulus application
import './bootstrap';

// * FONT-AWESOME NOT WORKING ?? WHY ??
// import 'font-awesome/css/font-awesome.css';

// * JQUERY IMPORT
// import $ from 'jquery';

// * ALLER CHERCHER UN FICHIER SPÉCIFIQUE
// import 'jquery/dist/jquery.min.js';

// * DÉFINIR $ COMME CONSTANTE
// const $ = require('jquery');

// * DÉFINIR $ COMME CONSTANTE GLOBALE
// global.$ = global.jQuery = $;

// * TEST DE FONCTION CLICK
// <button id="special-btn">SPECIAL BUTTON</button>
// $("#special-btn").click(function () {
//     $("#special-btn").hide();
// });

// * MATERIALIZE CSS
import 'materialize-css/dist/css/materialize.min.css';

// * MATERIALIZE JS
import 'materialize-css/dist/js/materialize.js';

// document.addEventListener('DOMContentLoaded', function () {
//     var modals = M.Modal.init(document.querySelectorAll('.modal'), {});
// });

document.addEventListener('DOMContentLoaded', function() {
    var elems = document.querySelectorAll('.modal');
    var instances = M.Modal.init(elems);
});

// * EXEMPLE POUR APPELER DIRECTEMENT DANS APP.JS UN AUTRE FICHIER JS : 
// import events from './events';

// * DOC SYMFONY WEBPACK ENCORE
// https://symfony.com/doc/current/frontend/encore/installation.html
// https://symfony.com/doc/current/frontend/encore/simple-example.html


// *** INSTALLATION WEBPACK ENCORE
// Commandes d'installation :

// * composer require symfony/webpack-encore-bundle
// * yarn install (ou * npm install)

// Pour créer la première fois le dossier public/build :
// * npm run dev

// Ensuite pour lancer une simple compilation des fichiers dans le dossier public/build :
// * npm run dev

// Pour lancer une compilation pour le développement (avec mise à jour auto : dès qu'un fichier css ou js est modifié, mise à jour auto !) :
// * npm run watch

// Pour lancer une compilation minifiée avec numéro de version (pour la prod - ça sert à versionner et à minifier les fichiers : chargement plus rapide des pages, suivi des versions des fichiers pour un éventuel client, etc.. La bonne chose à faire!) :
// * npm run build

